// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./font/NunitoSans-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./font/NunitoSans-Bold.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:NunitoSan;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}@font-face{font-family:NunitoSanBold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\")}.cookieControl__BarContainer{background-color:#db3539;bottom:0;font-family:NunitoSan,serif!important;left:0;position:absolute;right:0}.cookieControl__Bar{background-color:rgba(0,0,0,.7)!important;height:100%}.cookieControl__ModalContent ul ul{font-family:NunitoSan,serif!important;height:0;visibility:hidden}.cookieControl__BarButtons>button:first-of-type{display:none}.cookieControl__ModalContent input+label:before{color:#000;content:\"OFF\";display:block;font-size:7px;font-weight:700;padding-left:1px;padding-top:2px}.cookieControl__ModalContent input:checked+label:before{color:#fff;content:\"ON\";font-size:8px;font-weight:400;padding-top:1px}html{color:#525252;font-family:NunitoSan,serif;overflow-y:hidden}.theme--light.v-application{color:#525252!important}.v-application{color:#525252;font-family:NunitoSan,serif!important}h1,h2,h3,h4{font-family:NunitoSanBold,serif}h2{letter-spacing:0;opacity:1}.v-text-field--filled{background-color:#fff}.v-btn{text-transform:none!important}.v-text-field--outlined,.v-text-field--solo{border-radius:10px!important}.v-text-field__details>div.v-messages.error--text{color:#525252!important;text-align:right}.ol-zoom{left:auto!important;right:.5em!important;top:.5em}.settingsCardMobile{bottom:0;width:100%}.settingsCardDesktop{top:.5em;width:300px}.settingsButtonMobile{bottom:1.5em}.settingsButtonDesktop{top:.5em}.settingsCloseButtonMobile{left:45vw}.settingsCloseButtonDesktop{left:275px;top:4em}.dialog-maxwidth{max-width:540px}.dialog-title{color:#525252;font-size:40px}.text-primary{color:#db3539!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
