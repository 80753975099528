import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _097a3a42 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _1aa5503a = () => interopDefault(import('../pages/airports.vue' /* webpackChunkName: "pages/airports" */))
const _d35cf3d6 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _00488eea = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _7e18a584 = () => interopDefault(import('../pages/forgot.vue' /* webpackChunkName: "pages/forgot" */))
const _1a4c8fc4 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _64d97ad0 = () => interopDefault(import('../pages/personal-info.vue' /* webpackChunkName: "pages/personal-info" */))
const _2504dd06 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _35832eaf = () => interopDefault(import('../pages/pwa.vue' /* webpackChunkName: "pages/pwa" */))
const _0a2dd17e = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _96918e9a = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _fe5ef010 = () => interopDefault(import('../pages/subscription.vue' /* webpackChunkName: "pages/subscription" */))
const _59a05988 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _235966a2 = () => interopDefault(import('../pages/update-plan.vue' /* webpackChunkName: "pages/update-plan" */))
const _29321507 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a1f9faca = () => interopDefault(import('../pages/airport/_airportcode/index.vue' /* webpackChunkName: "pages/airport/_airportcode/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _097a3a42,
    name: "about"
  }, {
    path: "/airports",
    component: _1aa5503a,
    name: "airports"
  }, {
    path: "/contact",
    component: _d35cf3d6,
    name: "contact"
  }, {
    path: "/faq",
    component: _00488eea,
    name: "faq"
  }, {
    path: "/forgot",
    component: _7e18a584,
    name: "forgot"
  }, {
    path: "/login",
    component: _1a4c8fc4,
    name: "login"
  }, {
    path: "/personal-info",
    component: _64d97ad0,
    name: "personal-info"
  }, {
    path: "/privacy",
    component: _2504dd06,
    name: "privacy"
  }, {
    path: "/pwa",
    component: _35832eaf,
    name: "pwa"
  }, {
    path: "/settings",
    component: _0a2dd17e,
    name: "settings"
  }, {
    path: "/signup",
    component: _96918e9a,
    name: "signup"
  }, {
    path: "/subscription",
    component: _fe5ef010,
    name: "subscription"
  }, {
    path: "/terms",
    component: _59a05988,
    name: "terms"
  }, {
    path: "/update-plan",
    component: _235966a2,
    name: "update-plan"
  }, {
    path: "/",
    component: _29321507,
    name: "index"
  }, {
    path: "/airport/:airportcode",
    component: _a1f9faca,
    name: "airport-airportcode"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
